<template>
    <div class="animated fadeIn">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Send Notification </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
          <b-row>
          
            <b-col sm="6">
              <b-form-group>
                <label for="country">Country</label>
                <b-form-select required v-model="notif.CountryID"
                 @change.native="getStateList($event.target.value)" id="country"
                  :plain="true"
                  value-field="CountryID"
                  text-field="CountryName"
                  :options=countries>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Country --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

              <b-col sm="6">
              <b-form-group>
                <label for="state">State</label>
                <b-form-select required v-model="notif.StateID"
                 @change.native="getCityList($event.target.value)" id="month1"
                  :plain="true"
                   value-field="StateID"
                  text-field="StateName"
                  :options=states>
                   <template slot="first">
                    <option :value="0" disabled>-- Select State --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="city">City</label>
                <b-form-select required v-model="notif.CityID" id="city"
                  :plain="true"
                   value-field="CityID"
                  text-field="CityName"
                  :options=cities>
                  <template slot="first">
                    <option :value="0" disabled>-- Select City --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

             <b-col sm="6">
              <b-form-group>
                <label for="city">Login Device</label>
                <b-form-select required v-model="notif.LoginDevice" id="loginDevice"
                  :plain="true"
                  :options="['Android', 'IOS', 'Web']">
                </b-form-select>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>

             <b-col sm="6">
              <b-form-group>
                <label for="city">Template</label>
                <b-form-select required v-model="notif.TemplateID"
                 @change.native="getTemplateContent($event.target.value)" id="template"
                  :plain="true"
                  value-field="TemplateID"
                  text-field="TemplateTitle"
                  :options=templates>
                   <template slot="first">
                    <option :value="0" disabled>-- Select Template --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <!-- <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                 <c-switch class="mx-1" color="primary" v-model="notif.NotificationIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />      
              </b-form-group>
            </b-col> -->
            </b-row>

          <b-row>
            
          </b-row>
          <b-row>
              <b-col sm="12">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Send Notification</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

        <!-- Send Notif to Single user -->
           <b-card>
          <div slot="header">
            <strong>Send Notification to Single User </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submitSingle">
            
            <b-row>
              <b-col sm="6">
                <b-input-group>
                <!-- Attach Left button -->
                <b-form-input type="text" v-model="search" placeholder="Search"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="primary" @click="searchUser()">
                    <i class="fa fa-search"></i> Search
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
              </b-col>

              <b-col sm="6">
                <b-form-select id="basicSelectLg"
                  v-model="singleNotif.UserID"
                  @change.native ="selectUser($event.target.value)"
                  size="md"
                  value-field="ID"
                  text-field="UserName"
                  :plain="true"
                  :options= users>
                   <template slot="first">
                    <option :value="0" disabled>-- Select User --</option>
                  </template>
                </b-form-select>
            </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="4">
                <b-form-group>
                  <label for="username">UserName</label>
                  <b-form-input type="text" disabled v-model="userName" id="username" placeholder="UserName"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group>
                  <label for="email">Email</label>
                  <b-form-input type="text" disabled v-model="email" id="email" placeholder="Email"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group>
                  <label for="phone">Mobile</label>
                  <b-form-input type="text" disabled v-model="mobile" id="mobile" placeholder="Mobile"></b-form-input>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
             <b-col sm="6">
              <b-form-group>
                <label for="city">Template</label>
                <b-form-select required v-model="singleNotif.TemplateID"
                 @change.native="getTemplateContent($event.target.value)" id="template"
                  :plain="true"
                  value-field="TemplateID"
                  text-field="TemplateTitle"
                  :options= templates>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Template --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <!-- <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                 <c-switch class="mx-1" color="primary" v-model="singleNotif.NotificationIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />      
              </b-form-group>
            </b-col> -->
            </b-row>

          <b-row>
            
          </b-row>
          <b-row>
              <b-col sm="12">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Send Single Notification</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>
        <!-- End Single User Notif -->

        <!-- Preview of Notification -->
        <b-card v-if="preview">
            <div slot="header">
            <strong>Notification Preview</strong>
          </div>
            {{previewText}}
        </b-card>
        <!-- End of Preview -->
      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import UserService from "@/services/UserService";
export default {
  name: "SendNotification",
  components: {
    cSwitch
  },
  data() {
    return {
      search: "",
      notif: {
        CountryID: 0,
        StateID: 0,
        CityID: 0,
        LoginDevice: "Android",
        TemplateID: 0
      },
      singleNotif: {
        UserID: 0,
        TemplateID: 0
      },

      previewText: "",
      userList: [],
      users: [],
      email: "",
      mobile: "",
      userName: "",
      countries: [],
      states: [],
      cities: [],
      templates: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      preview: false
    };
  },
  mounted() {
    this.getCountries();
    this.getTemplates();
    this.preview = false;
  },
  methods: {
    getTemplates: function() {
      this.templates = [];
      MasterService.getTemplates()
        .then(response => {
          const { data } = response;
          if (data.Status && data.TemplateList.length > 0) {
            this.templates = data.TemplateList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCountries: function() {
      this.countries = [];
      // let res = await MasterService.getCountriesDDL()
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getStateList: function(CountryID) {
      this.states = [];
      let payload = {
        CountryID
      };
      MasterService.getStatesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.states = data.StateList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCityList: function(StateID) {
      this.cities = [];
      let payload = {
        StateID
      };
      MasterService.getCitiesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CityList.length > 0) {
            this.cities = data.CityList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.notif.CountryID == "") {
        this.showMessageSnackbar("Please select the Country.");
        return false;
      } else if (this.notif.StateID == 0) {
        this.showMessageSnackbar("Please select a State.");
        return false;
      } else if (this.notif.CityID == 0) {
        this.showMessageSnackbar("Please select a City.");
        return false;
      } else if (this.notif.TemplateID == 0) {
        this.showMessageSnackbar("Please select a Template.");
        return false;
      } else if (this.singleNotif.UserID == 0) {
        this.showMessageSnackbar("Please select the User.");
        return false;
      } else if (this.userName == "") {
        this.showMessageSnackbar("User Name Cannot be empty..");
        return false;
      } else if (this.email == "") {
        this.showMessageSnackbar("Email Cannot be empty..");
        return false;
      } else if (this.mobile == "") {
        this.showMessageSnackbar("mobile Cannot be empty..");
        return false;
      } else {
        return true;
      }
    },
    submitFinalData: function() {},
    submit: function() {
      // console.log("this.notif :", this.notif);
      // let res = await MasterService.sendNotification(this.notif);
      MasterService.sendNotification(this.notif)
        .then(response => {
          const { data } = response;
          data && data.Message.length > 0
            ? this.showMessageSnackbar(data.Message)
            : "";
          if (data.Status) {
            this.notif = {
              CountryID: 0,
              StateID: 0,
              CityID: 0,
              LoginDevice: "Android",
              TemplateID: 0
            };
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    submitSingle: function() {
      // console.log("this.singleNotif :", this.singleNotif);
      // let res = await MasterService.sendNotification(this.singleNotif);
      MasterService.sendNotification(this.singleNotif)
        .then(response => {
          const { data } = response;
          data && data.Message.length
            ? this.showMessageSnackbar(data.Message)
            : "";
          if (data.Status) {
            this.singleNotif = {
              UserID: 0,
              TemplateID: 0
            };
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    searchUser: function() {
      let payload = {
        s: this.search
      };
      this.userList = [];
      this.users = [];
      this.singleNotif.UserID = 0;
      // let res = await UserService.searchSingleUser(payload);
      // this.userList = res.data;
      UserService.searchSingleUser(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.UserList.length > 0) {
            this.userList = data.UserList;
            this.users = data.UserList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    selectUser: function(UserID) {
      this.userList.map(u => {
        if (u.ID == UserID) {
          console.log("User :", u);
          this.email = u.Email;
          this.mobile = u.Mobile;
          this.userName = u.UserName;
        }
      });
    },
    getTemplateContent: function(TemplateID) {
      this.preview = true;
      // let res = await MasterService.getTemplateByID(TemplateID);
      // this.previewText = res.data[0].TemplateBody;
      let payload = {
        TemplateID
      };
      MasterService.getTemplateByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.TemplateList.length > 0) {
            this.previewText = data.TemplateList[0].TemplateBody;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
</style>
